import instance from "../instance";


//register user
export const registerAuth = async (data) => {
  let response = await instance({
    url: "api/user/register",
    method: "POST",
    data,
  });

  return response.data;
};

//login user
export const loginAuth = async (data) => {
    let response = await instance({
      url: "api/user/login",
      method: "POST",
      data,
    });
  
    console.log("Hello ");
    return response.data;
  };

//login user
export const seprateloginAuth = async (data) => {
    let response = await instance({
      url: "api/user/loginseparate",
      method: "POST",
      data,
    });
    console.log("Hello Separate");
    return response.data;
  };

  // validate login 2fa
  //login user
export const validate2FALogin = async (data) => {
  let response = await instance({
    url: "api/user/validate/login/2fa",
    method: "POST",
    data,
  });

  return response.data;
};

  //verify user email
  export const VerifyEmail = async (data) => {
    let response = await instance({
      url: `api/user/verify/login/${data}`,
      method: "GET",
    });
    return response.data;
  };
