import { Helmet } from "react-helmet-async";
import { HeaderLayout } from "../../../layout/DashboardLayout/Header";
import { Footer } from "../../../layout/DashboardLayout/Footer";
import useGetdata from "../../../hooks/useGetData";
import account_vector from "../../../img/account_vector.png";
import { MonthlyDepositWithdrawChart } from "./chart/MonthlyDepositWithdrawChart";
import { getAccountsWithDetail, getOpenPositionData } from "../../../api/account";
import { useEffect, useState } from "react";
import { CoverBackground } from "../secondHeaders/CoverBackground";
import {
  TotalDeposit,
  TotalLiveAccount,
  TotalWithdraw,
} from "../../../api/allTotal";
import { getUserDetails, getUserSettings } from "../../../api/userDetails";
import { Link, useNavigate } from "react-router-dom";
import { LoaderBar } from "../../../components/LoaderBar";
import DataTable from "../../../components/DataTable";
import { formatDate } from "../../../utility/formatTime";
import { useKYCStatus } from "../../../context/KycContext";
import { getSystemSettings } from "../../../api/systemSettings";
import { StyleModal } from "../../../components/StyleModal";
import { getBanner } from "../../../api/banner";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import { Boxes } from "./Boxes";



export const ClientDashboard = () => {
  const [searched, setSearched] = useState("");
  const navigate = useNavigate();
  const[open, setOpen] = useState(false)
  const {setAllMenu} = useKYCStatus()

  const {data: userbanner} = useGetdata(
    "userbanner",
    getBanner
  )

  useEffect(() => {

    fetchUserBanners();
  }, []);

  const fetchUserBanners = async () => {
      if(userbanner?.data && userbanner?.data?.length > 0){
        setOpen(true);
      }
    
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  
// slider settings
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    />
  );
}
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  //get live account list details
  const { data: allaccountdetails, isLoading } = useGetdata(
    "allaccountdetails",
    getAccountsWithDetail
  );

  const sortedAccountDetails = allaccountdetails?.data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  // get open position data
  const { data: openpositionData } = useGetdata(
    "openpositionData",
    getOpenPositionData
  );

  //get user details
  const { data: userDetails } = useGetdata("userDetails", getUserDetails);

  //total live account
  const { data: totalmtAccount } = useGetdata(
    "totalmtAccount",
    TotalLiveAccount
  );

  //total deposit
  const { data: totalDeposit } = useGetdata("totalDeposit", TotalDeposit);
  //total withdraw
  const { data: totalWithdraw } = useGetdata("totalWithdraw", TotalWithdraw);


  const headers = [
    { label: "SR. No.", key:"", sortable: true },
     { label: "Login ID", key:"Login", sortable: true },
    { label: "Symbol", key:"Symbol", sortable: true },
    { label: "Date", key:"TimeCreate", sortable: true },
    { label: "Expert Position ID", key:"ExpertPositionID", sortable: true },
    { label: "Open Price", key:"PriceOpen", sortable: true },
    { label: "Volume", key:"Volume", sortable: true },
    { label: "S/L", key:"PriceSL", sortable: true },
    { label: "T/P", key:"PriceTP", sortable: true },
    { label: "Current Price", key:"PriceCurrent", sortable: true },
    { label: "Profit", key:"Profit", sortable: true },
    { label: "Action", key:"Action", sortable: true },
  ]

  const filteredData = openpositionData?.data?.filter(
    (item) =>
      {
        item.Login && item.Login.toString().includes(searched) ||
    item.Symbol && item.Symbol.toString().includes(searched) ||
  item.Profit && item.Profit.toString().includes(searched) ||
  item.PriceOpen && item.PriceOpen.toString().includes(searched) ||
  item.PriceCurrent && item.PriceCurrent.toString().includes(searched)


      //     item.OpenPrice.toString().includes(searched) ||
      //     item.profit.toLowerCase().includes(searched.toLowerCase());
       }
  );
  const {data: userSetting} = useGetdata(
    "userSetting",
    getUserSettings
  )

  //get system settings
      
  const { data: systemSetting } = useGetdata(
    "systemSetting",
    getSystemSettings
  )
  
  let session = sessionStorage.getItem('loggedin');
  
  useEffect (()=>{
    if(!isLoading){
      if(systemSetting?.data?.kyc_register === 1) {
        setAllMenu(true)
      }
      if(systemSetting?.data?.kyc_register === 1 && userDetails?.data?.kyc_verified === null) {
        setAllMenu(true)
        // navigate("/uploadKyc")
      }
    if(systemSetting?.data?.kyc_register === 0 && userDetails?.data?.kyc_verified === null) {
      setAllMenu(false)
      navigate("/uploadKyc")
    }
    // if(userSetting?.data?.disable_kyc === 1 && userDetails?.data?.kyc_verified === null) {
    //   setAllMenu(true)
    //   navigate("/uploadKyc")
    // }
  }

  },[userDetails, systemSetting, navigate])

  return (
    <>
      <Helmet>
        <title>Client Dashboard | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <HeaderLayout />
      <CoverBackground />
      <main>
        {/* <!-- start boxes --> */}
        <Boxes />
        {/* <!-- end boxes --> */}
        {/* <!-- start Monthly deposit vs  Withdrawal --> */}
        <section className="container dash_new_withdrawal">
          <div className="spacer main_withdrawal justify-content-center">
            <div className=" Withdrawal_title">
              <h2 className="text-black">Deposit vs Withdrawal Chart</h2>
            </div>
            <div className="dash_img row">
              <div className="col-md-7 text-center">
                <div className="chart-light">
                  <MonthlyDepositWithdrawChart />
                </div>
              </div>
              <div className="col-md-5">
                  {
                    isLoading ? <LoaderBar /> :
                    <>
                <div className="singleacbxscroll">
                  {
                    sortedAccountDetails?.map((data)=>(
                  <div className="singleacbx" key={data.id}>
                     
                    <div className="accountbxsn">
                      <div className="accountbxsncl1">
                        <div className="accountbxsngp">
                          <h5>{data.groupname} </h5>
                          <p>MT5 : {data.account_no}</p>
                        </div>
                        <div className="accountbxsnbal">
                          <p>Balance</p>
                          <h3>{!data.Balance ? "0" : parseFloat(data.Balance).toFixed(2)} USD</h3>
                        </div>
                      </div>
                      <div className="accountbxsncl2">
                        <div className="accountbxsnlev">
                          <p className="accountbxsnlbl">Leverage</p>
                          <p className="accountbxsnval">{data?.Leverage || 0} </p>
                        </div>
                        <div className="accountbxsnmpas">
                          <p className="accountbxsnlbl">Equity</p>
                          <p className="accountbxsnval">{!data.Equity  ? "0" : parseFloat(data.Equity).toFixed(2)}</p>
                        </div>
                        <div className="accountbxsnipas">
                          <p className="accountbxsnlbl">Credit</p>
                          <p className="accountbxsnval">{!data.Credit ? "0" : parseFloat(data.Credit).toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                    <div className="accountbxtg">
                      <div className="ribbon ribbon-top-left">
                        <span>Live</span>
                      </div>
                    </div>
                  </div>   
                    ))
                  }
                </div>
                </>
                    }
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end Monthly deposit vs  Withdrawal -->  */}

        {/* <!-- start positions tabel --> */}
        <section className="container">
          <div className="TableBox">
            <div className="table_title d-flex justify-content-between align-items-center pe-3">
              <div className="position_name d-flex align-items-center">
                <h3>Open Positions</h3>
              </div>
              <div className="dataTables_filter d-sm-flex align-items-center">
                <label for="Search">Search:</label>
                <input
                  type="search"
                  className="w-100"
                  value={searched}
                  onChange={(e) => setSearched(e.target.value)}
                />
              </div>
            </div>
            {/*<hr className="m-0" />*/}
            <div className="table_filed table-responsive">
                    <DataTable
                    data={filteredData}
                    headers={headers}
                    renderRow={(rowData) => (
                      <>
                        <td className="text-center">{rowData.Login}</td>
                        <td className="text-center">{rowData.Symbol}</td>
                        <td className="text-center">{formatDate(new Date(parseInt(rowData.TimeCreate) * 1000).toLocaleDateString())}</td>
                        <td className="text-center">{rowData.ExpertPositionID}</td>
                        <td className="text-center">{rowData.PriceOpen}</td>
                        <td className="text-center">{rowData.Volume}</td>
                        <td className="text-center">{rowData.PriceSL}</td>
                        <td className="text-center">{rowData.PriceTP}</td>
                        <td className="text-center">{rowData.PriceCurrent}</td>
                        <td className="text-center">
                        {rowData.Profit >= 0 ? <span className="plusvalue">{rowData.Profit}<i className="fa-solid fa-arrow-trend-up"></i></span>:
              <span className="minusvalue">{rowData.Profit}<i className="fa-solid fa-arrow-trend-down"></i></span>}
                        </td>
                        <td> {rowData.Action === 0 ? <span className="b_btn text-center"><span>BUY</span></span> : "" ||
              rowData.Action === 1 ?
              <span className="s_btn"><span>SELL</span></span> :""}</td>
                      </>
                    )} />
            </div>
          </div>
        </section>
        {/* <!-- end positions tabel -->  */}

        {/* <!-- start open new account --> */}
        <section className="dash_account container spacer">
          <div className="main_dash_account">
            <div className="row">
              <div className="col-lg-6 order-lg-1 order-2 account_title ">
                <h2 className="text-black">Open a New Account!</h2>
                <p>
                Streamlined view of your diverse Forex accounts for seamless management and analysis.
                </p>
                { systemSetting?.data?.kyc_register === 1  ?
                <Link to="/account/openLiveAccount" className="btn btn-danger">
                  Open Account
                </Link> :
                userDetails?.data.kyc_verified === 1 ? 
                <Link to="/account/openLiveAccount" className="btn btn-danger">
                  Open Account
                </Link> :
                <Link to="" className="btn btn-danger">
                Open Account
              </Link>}
              </div>
              <div className="col-lg-6 order-lg-2 order-1 account_vector pb-lg-2 text-center">
                {" "}
                <img src={account_vector} alt="" />{" "}
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end open new account -->  */}
      </main>
      <Footer />

      <StyleModal
        title="Note"
        open={open}
        // width={800}
        onCancel={handleCloseModal}
        footer={[]}>
          <div className="row mb-2 p-1">
           <div className=" col-sm-12 mb-3" >
           <Slider {...settings}>
      {
          userbanner?.data?.map((banner)=>(
        <div key={banner.id}>
          <img src={`${process.env.REACT_APP_API_URL}images/bannerUploads/${banner.image}`} alt="Slide1"  width="100%" height="200px" style={{borderRadius:"5px", border:"none !important"}} />
        </div>
          
          ))
        }
      </Slider>
</div>
</div>
        </StyleModal>
    </>
  );
};
