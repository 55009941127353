import instance from "../instance";

//ib requested
export const IBRequested = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/user/ib/request",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

    //ib Register
    export const IBUserRegister = async (data) => {
      let token = localStorage.getItem("token");
      const{  ib_key ,allData} = data;
        let response = await instance({
          url:`api/ib/register/${ib_key}`,
          method:"POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: allData,
        });
      return response.data;
    }; 

   //ib status

   export const IBStatusCheck = async (data) => {

    let response = await instance({
      url:`api/user/ibstatus`,
      method:"POST",
      data,
    });
  return response.data;
};

//ib withdraw
export const WithdrawIBs = async (data) => {
  let token = localStorage.getItem("token");
    let response = await instance({
      url:`api/ib/commission/withdraw`,
      method:"POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  return response.data;
}; 

//get team deposit report
export const getTeamDepositReport = async (data) => {
  let token = localStorage.getItem("token");
  const {startDate, endDate} = data;
  let response = await instance({
    url: `api/ib/deposit/report?startDate=${startDate}&endDate=${endDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};
//get withdraw report
export const getTeamWithdrawReport = async (data) => {
  let token = localStorage.getItem("token");
  const {startDate, endDate} = data;
  let response = await instance({
    url: `api/ib/withdraw/report?startDate=${startDate}&endDate=${endDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get ib commission list
export const GetIBCommissionList = async (data) => {
  let token = localStorage.getItem("token");
  const {startDate, endDate} = data;
  let response = await instance({
    url: `api/ib/commission/all?startDate=${startDate}&endDate=${endDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get ib  level
export const GetIBLevel = async (data) => {
  let token = localStorage.getItem("token");
  // const {startDate, endDate, type, paymentmethod} = data;
  let response = await instance({
    url: `api/ib/levels/all`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get ib one level
export const GetIBOneLevel = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/onelevel`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get ib tree data
export const GetIBTreeData = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/treedata`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get single ib tree data
export const GetSingleIBTreeDetail = async (data) => {
  let token = localStorage.getItem("token");
  const{id} =data;
  let response = await instance({
    url: `api/ib/treesingledetails/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get total Volume
export const getTotalVolume = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/totalvolume`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//total Client
export const getTotalClient = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/totalclients`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get Total Available Commission
export const getTotalCommission = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/totalavailablecommission`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get total withdraw commission
export const getTotalWithdrawCommission = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/totalwithdrawcommission`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get Active traders 
export const getActiveTrader = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/activetraders`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get active sub ibs 
export const getActiveSubIBs = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/activesubib`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get top earning sub ibs
export const GetTopEarningSubIB = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/topearningsubibs`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get yearly top earning sub ibs
export const GetYearlyTopEarningSubIB = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/topearningsubibs?filter=yearly`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get month top earning sub ibs
export const GetMonthlytopEarningSubIB = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/topearningsubibs?filter=month`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get week top earning sub ibs
export const GetWeektopEarningSubIB = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/topearningsubibs?filter=week`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get total live account info
export const GetLiveAccountInfo = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/liveaccountinfo`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get kyc info
export const GetKycInfo = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/kycinfo`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get ftd info
export const GetFTDInfo = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/ftdinfo`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//ib monthly commission chart
export const CommissionChartMonthly = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/commissionmonthly`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get ib monthly deposit withdraw chart
export const MonthlyDepositWithdrawIBChart = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/depositwithdrawgraph`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get ib withdraw Report

export const getIBWithdrawReport = async (data) => {
  let token = localStorage.getItem("token");
  const {startDate, endDate} = data;
  let response = await instance({
    url: `api/user/report/ibwithdraw?startDate=${startDate}&endDate=${endDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get totalibteam deposit and withdraw
export const getToalIBTeamDepositandWithdraw = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/ib/totalteamdepositsandwithdraws`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};