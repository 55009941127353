import { HelmetProvider } from "react-helmet-async";
import { Login } from "./section/auth/Login";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute";
import SimpleLayout from "./layout/simple/SimpleLayout";
import { Page404 } from "./section/pages/Page404";
import { Register } from "./section/auth/Register";
import { ForgotPassword } from "./section/auth/ForgotPassword";
import { NewPassword } from "./section/auth/NewPassword";
import { UploadKyc } from "./section/@dashboard/kyc/UploadKyc";
import { ChangePassword } from "./section/auth/ChangePassword";
import { MyProfile } from "./section/auth/MyProfile";
import { IBRegister } from "./section/@dashboard/ib/IbRegister";
import { OpenLiveAccount } from "./section/@dashboard/account/OpenLiveAccount";
import { ClientDashboard } from "./section/@dashboard/dashboard/ClientDashboard";
import { AccountList } from "./section/@dashboard/account/AccountList";
import { ChangeMT5Password } from "./section/@dashboard/account/ChangeMt5Password";
import { DocumentUploadFiles } from "./section/@dashboard/kyc/DocumentUploadList";
import { BankDetails } from "./section/@dashboard/compliance/BankDetails";
import { DepositMethod } from "./section/@dashboard/deposit/DepositMethod";
import { DepositBank } from "./section/@dashboard/deposit/DepositBank";
import { DepositCash } from "./section/@dashboard/deposit/DepositCash";
import { WithdrawMethod } from "./section/@dashboard/withdraw/WithdrawMethod";
import { WithdrawBank } from "./section/@dashboard/withdraw/WithdrawBank";
import { WithdrawCash } from "./section/@dashboard/withdraw/WithdrawCash";
import { InternalTransfer } from "./section/@dashboard/transfer/InternalTransfer";
import { MT5ToWallet } from "./section/@dashboard/wallet/Mt5ToWallet";
import { WalletToMT5 } from "./section/@dashboard/wallet/WalletToMT5";
import { DepositReport } from "./section/@dashboard/report/DepositReport";
import { WithdrawReport } from "./section/@dashboard/report/WithdrawReport";
import { TransferReport } from "./section/@dashboard/report/TransferReport";
import { MyTickets } from "./section/@dashboard/helpDesk/MyTickets";
import { TicketDetails } from "./section/@dashboard/helpDesk/tabs/TicketDetails";
import { NewTickets } from "./section/@dashboard/helpDesk/CreateTicket";
import { IBDashboard } from "./section/@dashboard/ib/IBDashboard";
import { MyClients } from "./section/@dashboard/ib/MyClients";
import { TreeChart } from "./section/@dashboard/ib/TreeCharts";
import { IBUserCommissionList } from "./section/@dashboard/ib/MyCommission";
import { TeamDepositReport } from "./section/@dashboard/ib/TeamDepositReport";
import { TeamWithdrawReport } from "./section/@dashboard/ib/TeamWithdrawReport";
import { IBWithdraw } from "./section/@dashboard/ib/IBWithdraw";
import { KYCStatusProvider } from "./context/KycContext";
import { IBRequest } from "./section/@dashboard/ib/IBRequest";
import { WalletHistoryReport } from "./section/@dashboard/wallet/WalletHistoryList";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleRedirectPage } from "./section/auth/GoogleRedirect";
import { Google2FAVerification } from "./section/@dashboard/google2fa/Google2FA";
import { ValidateLogin2FA } from "./section/auth/ValidateLogin2FA";
import { DealReport } from "./section/@dashboard/report/DealReport";
import { IBWithdrawReport } from "./section/@dashboard/report/IBWithdrawReport";
import { NewsList } from "./section/@dashboard/news/NewsList";
import { EmailVerification } from "./section/auth/EmailVerification";
import { Tidio } from "./components/Tidio";
import useGetdata from "./hooks/useGetData";
import { getUserDetails } from "./api/userDetails";
import { getSystemSettings } from "./api/systemSettings"
import { message } from "antd";
import { WithdrawProtectedRoute } from "./components/WithdrawProtectedRoute";
import { useContext, useEffect } from "react";
import { IBLogin } from "./section/auth/IBLogin";

function App() {
  const token = localStorage.getItem('token')

  const { data: userDetails } = useGetdata(
    "userDetails",
    getUserDetails
  );

  const { data: systemSetting } = useGetdata(
    "systemSetting",
    getSystemSettings
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (systemSetting?.data?.kyc_register === 0 && userDetails?.data?.kyc_verified === null) {
      navigate("/")
    }
  }, []);

  let session = sessionStorage.getItem("loggedin");
  const clearStorage = () => {
    sessionStorage.clear(session);
    localStorage.clear(token);
  };

  useEffect(() => {
   
    // Set interval to clear storage every 2 hours (7200000 milliseconds)
    const interval = setInterval(() => {
      clearStorage();
    }, 7200000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);


  
  return (
    <div className="App">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <HelmetProvider>
          <KYCStatusProvider>
            <Routes>
              <Route element={<SimpleLayout />}>
                <Route path='/' element={<Navigate to='/login' />} />
                <Route path='/login' element={<Login />} />
                <Route path='/iblogin' element={<IBLogin />} />
                <Route path='/register' element={<Register />} />
                <Route path='/verifyEmail/:token' element={<EmailVerification />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/new-password/:token' element={<NewPassword />} />
                <Route path='/googleredirect/:token' element={<GoogleRedirectPage />} />

              </Route>
              <Route path='/client/verifylogin2fa' element={<ValidateLogin2FA />} />

              <Route element={<ProtectedRoute />}>
                <Route path='/clientDashboard' element={<ClientDashboard />} />

                {/* kyc */}
                <Route path='/uploadKyc' element={<UploadKyc />} />

                <Route path='/kyc/documentupload' element={<DocumentUploadFiles />} />

                {/* compliance */}
                <Route path='/kyc/bankDetails' element={<BankDetails />} />

                {/* account */}
                <Route path='/account/openLiveAccount' element={<OpenLiveAccount />} />
                <Route path='/account/accountList' element={<AccountList />} />
                <Route path='/account/changemt5password' element={<ChangeMT5Password />} />

                {/* deposit */}
                <Route path='/fund/depositMethod' element={<DepositMethod />} />

                <Route path='/fund/depositBank' element={<DepositBank />} /> :

                <Route path='/fund/depositCash' element={<DepositCash />} />

                {/* Withdraw */}
                <Route path='/fund/withdrawMethod' element={<WithdrawMethod />} />
                <Route element={<WithdrawProtectedRoute />}>
                  <Route path='/fund/withdrawBank' element={<WithdrawBank />} />
                  <Route path='/fund/withdrawCash' element={<WithdrawCash />} />
                    <Route path='/ib/ibWithdraw' element={<IBWithdraw />} />

                </Route>
                <Route path='/client/enable2fa' element={<Google2FAVerification />} />


                {/* transfer */}
                <Route path='/fund/internalTransfer' element={<InternalTransfer />} />

                {/* wallet */}
                {/* <Route path='/wallet/mt5toWallet' element={<MT5ToWallet />} />
            <Route path='/wallet/walletToMt5' element={<WalletToMT5 />} /> */}
                <Route path='/wallet/walletHistoryReport' element={<WalletHistoryReport />} />


                {/* reports */}
                <Route path='/report/depositReport' element={<DepositReport />} />
                <Route path='/report/withdrawReport' element={<WithdrawReport />} />
                <Route path='/report/ibwithdrawReport' element={<IBWithdrawReport />} />
                <Route path='/report/internaltransferReport' element={<TransferReport />} />
                <Route path='/report/dealreport' element={<DealReport />} />

                {/* ticket */}
                <Route path='/ticket/mytickets' element={<MyTickets />} />
                <Route path='/ticket/ticketDetails/:ticket_id' element={<TicketDetails />} />
                <Route path='/ticket/newTicket' element={<NewTickets />} />

                {/* news */}
                <Route path='/client/news' element={<NewsList />} />

                {/* ib */}
                <Route path='/ib/ibDashboard' element={<IBDashboard />} />
                <Route path='/ib/myClients' element={<MyClients />} />
                <Route path='/ib/treeChart' element={<TreeChart />} />
                <Route path='/ib/ibuserCommissionList' element={<IBUserCommissionList />} />
                <Route path='/ib/teamDepositReport' element={<TeamDepositReport />} />
                <Route path='/ib/teamWithdrawReport' element={<TeamWithdrawReport />} />
                <Route path='/ib/ibRequest' element={<IBRequest />} />

                <Route path='/changePassword' element={<ChangePassword />} />
                <Route path='/myProfile' element={<MyProfile />} />
                  
              </Route>
              <Route path='/register/:ib_key' element={<IBRegister />} />
              <Route path='/*' element={<Page404 />} />

            </Routes>
          </KYCStatusProvider>
        </HelmetProvider>
      </GoogleOAuthProvider>
      {
        token ? <Tidio /> : null
      }
    </div>
  );
}

export default App;
