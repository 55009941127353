import { useState } from "react";
import { formatDate } from "../../../utility/formatTime";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import DataTable from "../../../components/DataTable";


export const IBUsersLevel = (props) => {
  const [searched, setSearched] = useState("");

  const { level } = props;

const headers = [
  { label: "Sr. No", key: "id", sortable: true },
  { label: "Name", key: "name", sortable: true },
  { label: "Email", key: "email", sortable: true },
  { label: "Phone", key: "phone", sortable: true },
  { label: "Total Lots ", key: "totallot", sortable: true },
  { label: "Total Commission", key: "totalcommission", sortable: true },
  { label: "IB Name", key: "ib_name", sortable: true },
  { label: "Registration Date", key: "createdAt", sortable: true },
]

  const filteredData = level?.levels?.filter((item) =>
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
  item.email && item.email.toLowerCase().includes(searched.toLowerCase()) 
  )

  //export to pdf 
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'Name',
      'Email',
      'Phone',
      'MT5 ID',
      'Total Lots',
      'Total Commission',
      'IB Name',
      'Registration Date'
    ];

    const rows = data?.map(item => [
      item.id,
      item.name,
      item.email,
      item.phone,
      item.account_no,
      item.lots,
      item.commission,
      item.ibName,
      formatDate(item.createdAt)
    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },

      },
      styles: {
        fontSize: 10,
        font: 'Exo'
      }
    });
    doc.save('Client-Level-report.pdf');
  };

  //   export excel format
  const exportToExcel = () => {
    const dataForExport = level?.levels?.map(item => ({
      'ID': item.id,
      'Name': item.name,
      'Email': item.email,
      'Phone': item.phone,
      'MT5 ID': item.account_no,
      'Total Lots': item.lots,
      'Total Commission': item.commission,
      'IB Name': item.ibName,
      'Date': formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'client-Level-report.xlsx';

    XLSX.writeFile(wb, filename);
  }


  return (
    <>
      <div className="tab-pane fade active show">
        <div className="row MyInvestment mb-4 mb-lg-4">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-sm-2 col-xl-2 mb-3">
                <div className="bg-light rounded-3 p-2 text-center h-100">
                  <div className="mb-0 display-6">{level?.lot ? level?.lot.toFixed(2) : 0}</div>
                  <p className="text-muted">Lot</p>
                </div>
              </div>
              <div className="col-sm-2 col-xl-2 mb-3">
                <div className="bg-light rounded-3 p-2 text-center h-100">
                  <div className="mb-0 h1">{level?.commission ? level?.commission.toFixed(2) : 0}</div>
                  <p className="text-muted">Commission</p>
                </div>
              </div>
              <div className="col-sm-2 col-xl-2 mb-3">
                <div className="bg-light rounded-3 p-2 text-center h-100">
                  <div className="mb-0 h1">{level?.deposit ? level.deposit.toFixed(2) : 0}</div>
                  <p className="text-muted">Deposit</p>
                </div>
              </div>
              <div className="col-sm-2 col-xl-2 mb-3">
                <div className="bg-light rounded-3 p-2 text-center h-100">
                  <div className="mb-0 h1">{level?.withdraw ? level.withdraw.toFixed(2) : 0}</div>
                  <p className="text-muted">Withdraw</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="TableBox">
          <div className="table_filed table-responsive">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="sign_btn d-flex justify-content-start">
                  <button className="btn btn-sm text-white p-2 m-2" onClick={() => exportToPDF(level?.levels)}><i className="fa fa-cloud-download" />  PDF</button>

                  <button className="btn btn-sm text-white p-2 m-2" onClick={() => exportToExcel(level?.levels)}><i className="fa fa-cloud-download" />  EXCEL</button>
                </div>
                <div className="dataTables_filter d-sm-flex align-items-center">
                  <label for="Search">Search: </label>
                  <input type="search" className="p-1 w-100"
                    value={searched}
                    onChange={(e) => setSearched(e.target.value)} />
                </div>
              </div>
            </div>
            <DataTable
              data={filteredData}
              headers={headers}
              renderRow={(rowData) => (
                <>
                  <td className="text-center">{rowData.name}</td>
                  <td className="text-center">{rowData.email}</td>
                  <td className="text-center">{rowData.phone}</td>
                  <td className="text-center">{rowData.totallot ? rowData.totallot.toFixed(2) : 0}</td>
                  <td className="text-center">{rowData.totalcommission ? rowData.totalcommission.toFixed(2) : 0}</td>
                  <td className="text-center">{rowData.ib_name}</td>
                  <td className="text-center">
                    <span className="t_time">{formatDate(rowData.createdAt)}</span></td>
                </>
              )} />
          </div>
        </div>
      </div>
    </>
  )
}